import "./styles.css";

/**
 * Hamburger icon used as a menu button.
 * 
 * @param {*} props 
 * @returns 
 */
export default function MenuIcon({open, dark}) {
    return (
        <div id="icon" className={(open ? "open" : "") + "" + (dark ? " dark" : "")}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
)
}