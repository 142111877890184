import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { isMobile } from "react-device-detect";
import { Spacer } from '../../PliantCryptoFrontend/Components';
import { INSTA_LINK, TWITTER_LINK, DISCORD_LINK, TIKTOK_LINK } from '../../constants/Links';
import instagramIcon from '../../images/insta-logo.png';
import twitterIcon from '../../images/twitter-logo.png';
import discordIcon from '../../images/discord-logo.png';
import tiktokLogo from '../../images/tiktok-logo.png';

import logo from "../../images/tatted-apes-logo.png";
import MenuIcon from '../MenuIcon/MenuIcon';

import "./styles.css";
import { getMainContainerWidthInPX } from '../MainContainer';

/**
 * Navigation bar containing the logo and menu icon. This also contains the implementaion of the off-canvas menu.
 * 
 * @returns 
 */
export default function NavBar() {
    const [expanded, setExpanded] = useState(false);
    return (
        <Navbar expand={false} className="mb-3" 
        style={{
            width: isMobile ? null : `${getMainContainerWidthInPX()}px`,
            left: `${(window.innerWidth - getMainContainerWidthInPX()) / 2}px`
        }} 
        fixed="top" onToggle={(expanded) => setExpanded(expanded)}>
            <Container fluid>
                <Navbar.Brand href="#">
                    <img
                        src={logo}
                        width={isMobile ? 50 : 90}
                        height={isMobile ? 50 : 90}
                    />
                </Navbar.Brand>
                <Navbar.Toggle className={"nav-icon"} aria-controls={`offcanvasNavbar-expand`} onClick={() => setExpanded((prev) => !prev)}>
                    <MenuIcon open={expanded} dark={false} />
                </Navbar.Toggle>
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="start"
                    style={{
                        width: isMobile ? null : '30vw'
                    }}
                >
                    <Offcanvas.Header className='open-menu'>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                            <img
                                src={logo}
                                width={isMobile ? 50 : 90}
                                height={isMobile ? 50 : 90}
                            />
                        </Offcanvas.Title>
                        <div className='nav-title'>
                            Tatted Apes Club
                        </div>
                        <Navbar.Toggle className={"nav-icon"} aria-controls={`offcanvasNavbar-expand`} onClick={() => setExpanded((prev) => !prev)}>
                            <MenuIcon open={expanded} dark={false} />
                        </Navbar.Toggle>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='open-menu'>
                        <Nav className="justify-content-end flex-grow-1 open-nav">
                            <div className='section-header'>
                                Explore Tatooka Island
                            </div>
                            <Nav.Link className={`navbar-link ${window.location.pathname === '/' ? 'selected' : ''}`} href="/">Home</Nav.Link>
                            <Nav.Link className={`navbar-link ${window.location.pathname === '/mint' ? 'selected' : ''}`} href="/mint">Mint</Nav.Link>
                            <Nav.Link className={`navbar-link ${window.location.pathname === '/roadmap' ? 'selected' : ''}`} href="/roadmap">Roadmap</Nav.Link>
                            <Spacer amount={'80px'} />
                            <div className='section-header'>
                                Check out our socials
                            </div>
                            <Nav.Link className={'social-link'} href={INSTA_LINK}>
                                <img
                                    src={instagramIcon}
                                    height={30}
                                />
                            </Nav.Link>
                            <Nav.Link className={'social-link'} href={TWITTER_LINK}>
                                <img
                                    src={twitterIcon}
                                    height={25}
                                />
                            </Nav.Link>
                            <Nav.Link className={'social-link'} href={DISCORD_LINK}>
                                <img
                                    src={discordIcon}
                                    height={25}
                                />
                            </Nav.Link>
                            <Nav.Link className={'social-link'} href={TIKTOK_LINK}>
                                <img
                                    src={tiktokLogo}
                                    height={25}
                                />
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}