import React from "react";
import islandImage from '../images/tatted-apes-beach-blur-1.png';
import MainContainer from "../components/MainContainer";
import SubContainer from "../components/SubContainer";
import Text from "../constants/Text";
import SocialBar from "../components/SocialBar";
import AnimatedImage from "../components/AnimatedImage";
import NavBar from "../components/NavBar/NavBar";
import { rgb } from "../Helpers";
import { BasicImage, Button, Spacer } from '../PliantCryptoFrontend/Components';

import opensea from '../images/opensea-logo.png';
import instagram from '../images/insta-logo.png';
import { isMobile } from "react-device-detect";
import TeamMember from "../components/TeamMember";
import viceApe from "../images/vice-ape.jpg";
import westieApe from "../images/westie-ape.jpg";
import timApe from "../images/tim-ape.jpg";
import TextComponent from "../components/TextComponent";
import FadingImageCarousel from "../components/FadingImageCarousel";
import { MAIN_APE_IMAGES } from "../images/ImageLists";
import StorySubContainer from "../components/StorySubContainer";
import { LIGHT_SHADOW, SHADOW_EFFECT_3D } from "../constants/StyleAttributes";
import { INSTA_LINK, OPENSEA_LINK } from "../constants/Links";
import axios from 'axios';


export default class Island extends React.Component {

    constructor() {
        super();
        this.contentSize = isMobile ? window.innerWidth * 0.9 : 600;
        window.addEventListener('resize', () => {
            this.forceUpdate();
        });
        this.state = {
            members: null,
            onlineMembers: null
        };
    }

    componentDidMount() {
        axios({
            method: 'get',
            url: `https://discord.com/api/v9/invites/tattedapesclub?with_counts=true&with_expiration=true`
        }).then((result) => {
            if (result && result.data && result.data.approximate_member_count && result.data.approximate_presence_count) {
                this.setState({ members: result.data.approximate_member_count, onlineMembers: result.data.approximate_presence_count });
            }
        });
    }

    renderAnimatedContainer() {
        const ih = 832;
        const iw = 1080;
        const size = 47;
        return (
            <AnimatedImage
                inputHeight={ih}
                inputWidth={iw}
                inputImage={islandImage}
                inputSrc={''}
            >
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Welcome to'}
                    fontSize={isMobile ? '17px' : '35px'}
                    fontFamily={'ARCO'}
                    width={window.innerWidth}
                    height={45}
                    xp={0.5}
                    yp={0.35}
                    style={{ opacity: '0.9' }}
                    shadowEffect={LIGHT_SHADOW}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Tatooka Island'}
                    fontSize={isMobile ? '31px' : '80px'}
                    fontFamily={'ARCO'}
                    width={window.innerWidth}
                    height={45}
                    xp={0.5}
                    yp={0.45}
                    shadowEffect={SHADOW_EFFECT_3D}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Home of the'}
                    fontSize={isMobile ? '11px' : '23px'}
                    fontFamily={'ARCO'}
                    width={window.innerWidth}
                    height={45}
                    xp={0.5}
                    yp={0.65}
                    shadowEffect={LIGHT_SHADOW}
                    style={{ opacity: '0.9' }}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Tatted Apes Club'}
                    fontSize={isMobile ? '17px' : '37px'}
                    fontFamily={'ARCO'}
                    width={window.innerWidth}
                    height={45}
                    xp={0.5}
                    yp={0.72}
                    shadowEffect={LIGHT_SHADOW}
                />
            </AnimatedImage>
        );
    }

    renderDiscordCommunity() {

    }

    renderApesContainer() {
        return (
            <SubContainer
                backgroundColor={rgb(10)}
            >
                <Spacer amount={isMobile ? '20px' : '40px'} />
                <FadingImageCarousel
                    contentSize={this.contentSize}
                    images={MAIN_APE_IMAGES}
                />
                <Spacer amount={isMobile ? '20px' : '40px'} />
            </SubContainer>
        );
    }

    renderSaleInfoContainer() {
        return (
            <SubContainer backgroundColor={rgb(10)}>
                <Spacer amount={'15px'} />
                <div style={{
                    fontSize: '15px',
                    color: rgb(210)
                }}>
                    Mint Your Ape Today!
                </div>
                <Spacer amount={'10px'} />
                <Button
                    title={'MINT NOW'}
                    action={() => {
                        window.location.href = '/mint';
                    }}
                    highlightStyle={{
                        opacity: 0.6
                    }}
                />
                <Spacer amount={'30px'} />
                <div style={{
                    fontSize: '13px',
                    color: rgb(120)
                }}>
                    View Our Collection On
                </div>
                <Spacer amount={'6px'} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <a style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: '16px',
                        border: '1.5px solid rgb(255,255,255)',
                        backgroundColor: rgb(30),
                        borderRadius: '6px',
                        padding: '4px 8px 4px 8px',
                        cursor: 'pointer',
                        color: 'white',
                        textDecoration: 'none'
                    }}
                        href={OPENSEA_LINK}
                    >
                        <BasicImage width={'22px'} image={opensea} />
                        <Spacer amount={'10px'} horizontal />
                        <div>OpenSea</div>
                    </a>
                    <Spacer amount={'12px'} horizontal />
                    <a style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: '16px',
                        border: '1.5px solid rgb(255,255,255)',
                        backgroundColor: rgb(30),
                        borderRadius: '6px',
                        padding: '4px 8px 4px 8px',
                        cursor: 'pointer',
                        color: 'white',
                        textDecoration: 'none'
                    }}
                        href={INSTA_LINK}
                    >
                        <BasicImage width={'22px'} image={instagram} />
                        <Spacer amount={'10px'} horizontal />
                        <div>Instagram</div>
                    </a>
                </div>
                <Spacer amount={'35px'} />
            </SubContainer>
        );
    }

    renderTeamContainer() {
        return (
            <SubContainer>
                <Spacer amount={'28px'} />
                <div style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    color: rgb(50)
                }}>
                    TEAM
                </div>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-evenly',
                    flexDirection: "row",
                    flexWrap: "wrap"
                }}>
                    <TeamMember name={'Vice'} title={'Founder'} links={['instagram', 'twitter']} image={viceApe} />
                    <TeamMember name={'Fanomonal'} title={'Web3 Dev'} links={['instagram']} />
                    <TeamMember name={'Cryptopotomus'} title={'Smart Contracts'} links={['instagram', 'twitter']} image={timApe} />
                    <TeamMember name={'Localcryptogod'} title={'Community Outreach'} links={['instagram', 'twitter']} image={westieApe} />
                </div>
            </SubContainer>
        );
    }

    renderRoadmap() {

    }

    render() {
        return (
            <>
                <NavBar />
                <MainContainer>
                
                    {this.renderAnimatedContainer()}
                    {this.renderSaleInfoContainer()}
                    <StorySubContainer
                        title={Text.storyTitle}
                        body={Text.story}
                        buttonProps={{
                            title: 'Learn More',
                            action: () => { window.location.href = '/roadmap' }
                        }}
                    />
                    {this.renderApesContainer()}
                    {this.renderTeamContainer()}
                    <SocialBar />
                </MainContainer>
            </>
        );
    }
}
