import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

/**
 * Addresses that make up the merkle tree.
 */
const addresses = [
    "0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2",
    "0x4B20993Bc481177ec7E8f571ceCaE8A9e22C02db",
    "0x78731D3Ca6b7E34aC0F824c42a7cC18A495cabaB",
    "0x617F2E2fD72FD9D5503197092aC168c91465E7f2",
    "0xf33D5aD4e67C40244DD36b93C6276f294E7E9AAF",
    "0xCaeF5a83567d54B2106438d1166465bF28fB2d8b"
];

/**
 * Generates a proof for the given address.
 */
export function generateMerkleTreeProof(address) {
    const leafNodes = addresses.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    const hexProof = merkleTree.getHexProof(keccak256(address));
    return hexProof;
}