import twitter from '../images/twitter-logo.png';
import instagram from '../images/insta-logo.png';
import { Spacer } from '../PliantCryptoFrontend/Components';
import { rgb } from '../Helpers';

export default function TeamMember({links, name, title, image}) {
    let linkDivs = [];
    for (const link of links) {
        if (linkDivs.length > 0) {
            linkDivs.push(<Spacer amount={'18px'} horizontal />);
        }
        if (link.includes('instagram')) {
            linkDivs.push(<a style={{
                width: '30px',
                height: '30px',
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
                backgroundImage: `url(${instagram})`
            }} href={link} />);
        }
        if (link.includes('twitter')) {
            linkDivs.push(<a style={{
                width: '30px',
                height: '30px',
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
                backgroundImage: `url(${twitter})`
            }} href={link} />);
        }
    }
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: rgb(46),
            borderRadius: '15px',
            width: '160px',
            EebkitBoxShadow: '0px 0px 24px -3px rgba(0,0,0,0.37)',
            boxShadow: '0px 0px 24px -3px rgba(0,0,0,0.37)',
            marginTop: '28px'
        }}>
            <Spacer amount={'16px'} />
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%'
            }}>
                <div style={{
                    textAlign: 'center',
                    fontSize: name.length > 10 ? '14px' : '18px'
                }}>{name}</div>
            </div>
            <Spacer amount={'16px'} />
            <div style={{
                width: '120px',
                height: '120px',
                borderRadius: '63px',
                backgroundColor: 'black',
                backgroundPosition: '50% 50%',
                backgroundSize: 'cover',
                borderWidth: '3px',
                borderColor: 'white',
                borderStyle: 'solid',
                backgroundImage: `url(${image})`
            }} />
            <div style={{
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%'
            }}>
                <div style={{
                    textAlign: 'center',
                    fontSize: '15px'
                }}>{title}</div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
                {linkDivs}
            </div>
            <Spacer amount={'20px'} />
        </div>
    );
}