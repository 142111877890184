import React from "react";
import { withWallet } from ".";
import { ethers } from 'ethers';


/**
 * The mint higher order component enabled a component with mint functionallity.
 * 
 * The following properties are required in the config:
 * 
 * The following props are provided to the component:
 *  - quantity: (int) The current quantity specified by the user.
 *  - quantityPlus: (function) When called, increases the quantity, maxing out at the quantity range max.
 *  - quantityMinus: (function) When called, decreases the quantity, minimizing at the quantity range min.

 */
export function withMint(WrappedComponent) {
    return withWallet(
        class extends React.Component {

            constructor() {
                super();
                this.state = {
                    quantity: 1 // The quantity, as an integer, of NFTs to mint for the user
                };
                this.quantityPlus = this.quantityPlus.bind(this);
                this.quantityMinus = this.quantityMinus.bind(this);
                this.getMintCostAsWeiString = this.getMintCostAsWeiString.bind(this);
                this.getMintCostAsEtherString = this.getMintCostAsEtherString.bind(this);
            }

            quantityPlus() {
                if (this.state.quantity < this.props.nft.mintRange[1]) {
                    this.setState((prevState) => ({
                        quantity: prevState.quantity + 1
                      }));
                }
            }

            quantityMinus() {
                if (this.state.quantity > this.props.nft.mintRange[0]) {
                    this.setState((prevState) => ({
                        quantity: prevState.quantity - 1
                      }));
                }
            }

            getMintCostAsWeiString() {
                return ethers.utils.parseEther(this.getMintCostAsEtherString()).toString();
            }

            getMintCostAsEtherString() {
                return (this.state.quantity * this.props.nft.price).toString();
            }

            render() {
                return <WrappedComponent
                    quantity={this.state.quantity}
                    quantityPlus={this.quantityPlus}
                    quantityMinus={this.quantityMinus}
                    getMintCostAsWeiString={this.getMintCostAsWeiString}
                    getMintCostAsEtherString={this.getMintCostAsEtherString}
                    {...this.props}
                />;
            }
        }
    );
}