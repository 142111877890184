import { getMainContainerWidthInPX } from "./MainContainer";

export default function SubContainer({width, height, backgroundColor, flexDirection, justifyContent, children, style}) {
    return (
        <div style={{
            width: width ? width : `${getMainContainerWidthInPX()}px`,
            height: height,
            backgroundColor: backgroundColor,
            display: 'flex',
            flexDirection: flexDirection ?? 'column',
            justifyContent: justifyContent ?? 'center',
            alignItems: 'center',
            overflow: 'hidden',
            ...style
        }}>
            {children}
        </div>
    );
}