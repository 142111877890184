
import ape1 from '../images/ape-1.jpg';
import ape2 from '../images/ape-2.jpg';
import ape4 from '../images/ape-4.jpg';
import ape5 from '../images/ape-5.jpg';
import ape6 from '../images/ape-6.jpg';
import ape7 from '../images/ape-7.jpg';
import ape10 from '../images/ape-10.jpg';
import ape11 from '../images/ape-11.jpg';
import ape12 from '../images/ape-12.jpg';
import ape13 from '../images/ape-13.jpg';
import ape14 from '../images/ape-14.jpg';
import ape15 from '../images/ape-15.jpg';
import ape16 from '../images/ape-16.jpg';
import ape17 from '../images/ape-17.jpg';
import ape18 from '../images/ape-18.jpg';
import ape19 from '../images/ape-19.jpg';

export const MAIN_APE_IMAGES = [
    ape1, ape2, ape4, ape5, ape6, ape7, ape10, ape11, ape12, ape13, ape14, ape15, ape16, ape17, ape18, ape19
].map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);