import React from "react";
import AnimatedImage from "../components/AnimatedImage";
import MainContainer from "../components/MainContainer";
import SocialBar from "../components/SocialBar";
import SubContainer from "../components/SubContainer";
import mintHutImage from '../images/mint-hut-blur-1.png';
import { rgb } from "../Helpers";
import { isMobile } from "react-device-detect";
import { MAIN_APE_IMAGES } from "../images/ImageLists";
import { Button, Quantity, Spacer } from "../PliantCryptoFrontend/Components";
import TextComponent from "../components/TextComponent";
import { withMint } from "../PliantCryptoFrontend/HOC";
import FadingImageCarousel from "../components/FadingImageCarousel";
import StorySubContainer from "../components/StorySubContainer";
import Text from "../constants/Text";
import { LIGHT_SHADOW, SHADOW_EFFECT_3D } from "../constants/StyleAttributes";
import NavBar from "../components/NavBar/NavBar";
import { mintLive, mintPresale } from "../contracts/Contract";


class Mint extends React.Component {

    constructor() {
        super();
        this.contentSize = isMobile ? window.innerWidth * 0.9 : 600;
        window.addEventListener('resize', () => {
            this.forceUpdate();
        });
        this.state = {
            transactionHash: null,
            isLoading: false
        };
        this.mintClicked = this.mintClicked.bind(this);
        this.mint = this.mint.bind(this);
        this.renderMintContainer = this.renderMintContainer.bind(this);
        this.renderAnimatedImageContainer = this.renderAnimatedImageContainer.bind(this);
    }

    async mintClicked() {
        if (this.state.isLoading) {
            return;
        }
        this.setState({ isLoading: true });
        if (this.props.wallet) {
            await this.mint();
        } else {
            await this.props.connectWallet(true);
        }
        this.setState({ isLoading: false });
    }

    async mint() {
        try {
            // Attempt presale mint
            const presaleTransaction = await mintPresale(
                this.props.contract,
                this.props.wallet,
                this.props.quantity,
                this.props.getMintCostAsWeiString()
            );
            if (presaleTransaction) {
                this.props.alert.success('Your presale NFT was minted successfully!')
                this.setState({ transactionHash: presaleTransaction.hash });
                return;
            }
            // Attempt live mint
            const liveTransaction = await mintLive(
                this.props.contract,
                this.props.quantity,
                this.props.getMintCostAsWeiString()
            );
            if (liveTransaction) {
                this.props.alert.success('Your NFT was minted successfully!')
                this.setState({ transactionHash: liveTransaction.hash });
                return;
            }
            // If neither are active
            this.props.alert.error('Sorry minting is not open at this time.');
        } catch (error) {
            // If there was an error during mint
            const code = error.code;
            if (code) {
                if (code === 'INSUFFICIENT_FUNDS') {
                    this.props.alert.error('You do not have enough funds to mint this NFT.');
                    return;
                }
            }
            console.log(error);
            this.props.alert.error('Sorry we could not mint your NFT at this time.');
        }
    }

    renderAnimatedImageContainer() {
        const ih = 832;
        const iw = 1080;
        return (
            <AnimatedImage
                inputHeight={ih}
                inputWidth={iw}
                inputImage={mintHutImage}
                inputSrc={''}
            >
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Mint Hut'}
                    fontSize={isMobile ? '45px' : '90px'}
                    fontFamily={'ARCO'}
                    width={window.innerWidth}
                    height={60}
                    xp={0.5}
                    yp={0.4}
                    shadowEffect={SHADOW_EFFECT_3D}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Join the Tatted Apes Club!'}
                    fontSize={isMobile ? '15px' : '33px'}
                    fontFamily={'ARCO'}
                    width={window.innerWidth}
                    height={6}
                    xp={0.5}
                    yp={0.55}
                    shadowEffect={LIGHT_SHADOW}
                    style={{
                        opacity: '0.9'
                    }}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Minted'}
                    fontSize={isMobile ? '11px' : '28px'}
                    fontFamily={'ARCO'}
                    width={100}
                    height={20}
                    xp={0.34}
                    yp={0.70}
                    style={{ opacity: 0.65 }}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={'Price'}
                    fontSize={isMobile ? '11px' : '28px'}
                    fontFamily={'ARCO'}
                    width={100}
                    height={20}
                    xp={0.66}
                    yp={0.70}
                    style={{ opacity: 0.65 }}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={`${this.props.nft.totalSupply}/${this.props.nft.maxSupply}`}
                    fontSize={isMobile ? '20px' : '38px'}
                    fontFamily={'ARCO'}
                    width={200}
                    height={20}
                    xp={0.34}
                    yp={0.78}
                />
                <TextComponent
                    inputHeight={ih}
                    inputWidth={iw}
                    text={`${this.props.nft.price} ETH`}
                    fontSize={isMobile ? '20px' : '38px'}
                    fontFamily={'ARCO'}
                    width={200}
                    height={20}
                    xp={0.66}
                    yp={0.78}
                />
            </AnimatedImage>
        );
    }

    renderMintContainer() {
        var content = (
            <>
                <Quantity
                    quantity={this.props.quantity}
                    minus={this.props.quantityMinus}
                    plus={this.props.quantityPlus}
                    textStyle={{
                        fontFamily: 'ARCO'
                    }}
                />
                <Spacer amount={'30px'} />
                <Button
                    loading={this.state.isLoading}
                    title={this.props.wallet ? `MINT (${Number(parseFloat(this.props.getMintCostAsEtherString()).toFixed(2))} ETH)` : 'CONNECT WALLET'}
                    action={this.mintClicked}
                    highlightStyle={{
                        opacity: 0.6
                    }}
                />
            </>
        );
        if (this.state.transactionHash) {
            content = (
                <>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            fontSize: '26px',
                            fontFamily: 'ARCO'
                        }}>
                            Congratulations!
                        </div>
                        <Spacer amount={'8px'} />
                        <div style={{
                            fontSize: '14px',
                            width: '84%',
                            textAlign: 'center',
                            color: rgb(180)
                        }}>
                            You are now the proud owner of a Tatted Ape! Click below to view your newly minted NFT!
                        </div>
                    </div>
                    <Spacer amount={'30px'} />
                    <Button
                        title={'VIEW NFT'}
                        action={() => { window.location.href = this.props.openseaWalletLink }}
                        highlightStyle={{
                            opacity: 0.6
                        }}
                    />
                    <Spacer amount={'18px'} />
                    <Button
                        title={'MINT MORE'}
                        textOnly
                        fontSize={'16px'}
                        action={() => this.setState({ transactionHash: null })}
                        highlightStyle={{
                            opacity: 0.6
                        }}
                    />
                </>
            );
        }
        return (
            <SubContainer
                backgroundColor={rgb(15, 15, 15)}
            >
                <Spacer amount={'25px'} />
                <FadingImageCarousel
                    images={MAIN_APE_IMAGES}
                    contentSize={this.contentSize}
                />
                <Spacer amount={'10px'} />
                <div style={{
                    width: `${this.contentSize * 0.9}px`,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div
                        style={{
                            fontSize: '15px',
                            color: rgb(80, 80, 80),
                            fontFamily: 'ARCO'
                        }}
                    >
                        {`${this.props.nft.totalSupply}/${this.props.nft.maxSupply}`}
                    </div>
                    <div
                        style={{
                            fontSize: '15px',
                            color: rgb(80, 80, 80),
                            fontFamily: 'ARCO'
                        }}
                    >
                        {`${this.props.nft.price} ETH`}
                    </div>
                </div>
                <Spacer amount={'10px'} />
                <div style={{
                    width: `${this.contentSize}px`,
                    height: '4px',
                    borderRadius: '2px',
                    backgroundColor: rgb(30, 30, 30)
                }} />
                <Spacer amount={'25px'} />
                {content}
                <Spacer amount={'30px'} />
            </SubContainer>
        );
    }

    render() {
        return (
            <>
                <NavBar />
                <MainContainer>
                    {this.renderAnimatedImageContainer()}
                    {this.renderMintContainer()}
                    <StorySubContainer
                        title={'Being part of the Tatted Apes Club'}
                        body={'Find out more about what what it\'s like to be a part of the Tatted Apes Club!'}
                        buttonProps={{
                            title: 'View Roadmap',
                            action: () => { window.location.href = '/roadmap' }
                        }}
                    />
                    <SocialBar />
                </MainContainer>
            </>
        );
    }
}

export default withMint(Mint);