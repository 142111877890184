import { isMobile } from "react-device-detect";
import { rgb } from "../Helpers";
import { Button, Spacer } from "../PliantCryptoFrontend/Components";
import SubContainer from "./SubContainer";

export default function StorySubContainer({ title, body, buttonProps }) {
    return (
        <SubContainer
            backgroundColor={rgb(20)}
        >
            <div style={{
            }}>
                <div style={{
                    marginTop: '35px',
                    marginLeft: '35px',
                    marginRight: '35px',
                    fontSize: '28px',
                    fontWeight: '900'
                }}>
                    {title}
                </div>
                <div style={{
                    margin: '20px 35px 35px 35px',
                    color: rgb(180),
                    fontSize: '13px',
                    whiteSpace: 'pre-line'
                }}>
                    {body}
                </div>
            </div>
            {buttonProps && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: isMobile ? 'column' : 'row'
                }}>
                    <Spacer amount={'35px'} horizontal />
                    <Button
                        title={buttonProps.title}
                        backgroundColor={rgb(35)}
                        fontSize={isMobile ? '15px' : undefined}
                        action={buttonProps.action}
                        highlightStyle={{
                            backgroundColor: rgb(80,80,80)
                        }}
                    />
                    <Spacer amount={'20px'} />
                </div>
            )}
            <Spacer amount={'20px'} />
        </SubContainer>
    );
}