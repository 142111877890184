import { getMainContainerLeftMargin, getMainContainerWidthInPX } from "./MainContainer";
import tapIndicator from '../images/tap-indicator.gif';

export default function TextComponent({inputWidth, inputHeight, fontSize, xp, yp, width, height, shadowEffect, text, fontFamily, style}) {
    const s1 = width / 2;
    const s2 = height / 2;
    const x = getMainContainerLeftMargin() + getMainContainerWidthInPX() * xp - s1;
    const y = getMainContainerWidthInPX() * inputHeight / inputWidth * yp - s2;
    return (
        <div style={{
            fontSize: fontSize,
            fontFamily: fontFamily,
            height: `${height}px`,
            width: `${width}px`,
            position: 'absolute',
            left: `${x}px`,
            top: `${y}px`,
            textAlign: 'center',
            lineHeight: `${height}px`,
            textShadow: shadowEffect,
            ...style
        }}>
            {text}
        </div>
    );
}