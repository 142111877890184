import { generateMerkleTreeProof } from "./MerkleTree";

/**
 * Checks if the presale is active.
 * @param contract 
 * @returns 
 */
export async function isPresaleActive(contract) {
    return await contract.isPresaleActive();
}

/**
 * Checks if the mint is active.
 * @param contract 
 * @returns 
 */
 export async function isMintActive(contract) {
    return await contract.isMintActive();
}

/**
 * Checks if a wallet is eligible for presale.
 */
export async function isPresaleEligible(contract, wallet, proof) {
    if (!(await isPresaleActive(contract))) {
        return false;
    }
    if (!proof) {
        return await contract.isWhitelisted(wallet, generateMerkleTreeProof(wallet));
    }
    return await contract.isWhitelisted(wallet, proof);
}

/**
 * Attempts to mint a token for presale. Returns transaction on success, 
 * null otherwise, and throws error if there was an issue.
 */
export async function mintPresale(contract, wallet, quantity, value) {
    const proof = generateMerkleTreeProof(wallet);
    if (!(await isPresaleEligible(contract, wallet, proof))) {
        return null;
    }
    const transaction = await contract.mintWhitelist(proof, quantity, { value: value });
    await transaction.wait();
    return transaction;
}

/**
 * Attempts to mint a token for live mint. Returns transaction on success, 
 * null otherwise, and throws error if there was an issue.
 */
 export async function mintLive(contract, quantity, value) {
    if (!(await contract.isMintActive())) {
        return null;
    }
    const transaction = await contract.mint(quantity, { value: value });
    await transaction.wait();
    return transaction;
}

