import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

export default function FadingImageCarousel({ contentSize, images }) {
    return (
        <div className="slide-container" style={{
            width: contentSize,
            height: contentSize
        }} >
            <Fade duration={1000} arrows={false} transitionDuration={600}>
                {images.map((imgSrc, index) => (
                    <div className="each-fade" key={index}>
                        <div className="image-container">
                            <img src={imgSrc} width={contentSize} height={contentSize} style={{
                                borderRadius: '10px'
                            }} />
                        </div>
                    </div>
                ))}
            </Fade>
        </div>
    );
}