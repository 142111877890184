import { isMobile } from "react-device-detect";

export default function MainContainer(props) {
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div style={{
                width: `${getMainContainerWidthInPX()}px`,
                padding: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {props.children}
            </div>
        </div>
    );
}

export function getMainContainerWidthInPX() {
    if (isMobile) {
        return window.innerWidth;
    }
    return window.innerWidth * 0.6;
}

export function getMainContainerLeftMargin() {
    return (window.innerWidth - getMainContainerWidthInPX()) / 2;
}