

export class ErrorMessages {
    static CONNECTION_NOT_ENABLED = 'Metamask is not enabled. Please enable the extension and try again.';
    static WALLET_COULD_NOT_BE_CONNECTED = 'Wallet could not be connected. Ensure you are signed into metamask and try again.';
    static MINT_FAILED = 'We could not mint your NFT at this time. Check metamask and try again.';
    static CONTRACT_COULD_NOT_BE_SETUP = 'We are having trouble connecting you to the contract. Please try again.';
    static FAILED_TO_GET_NFT_INFO = 'Failed to get NFT info.';
    static GENERIC_REQUEST_ERROR_RETRY_MESSAGE = 'Request failed. Please try again.';
    static SIGNATURE_REQUIRED = 'Signature failed. Please try again.';
}