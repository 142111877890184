import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Island from './pages/Island';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import AlertTemplate from 'react-alert-template-basic';
import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import Mint from './pages/Mint';
import { pliantConfig } from './PliantCryptoFrontend';
import abi from './contracts/tattedapes.json';
import { NFTInfo } from './PliantCryptoFrontend/HOC';
import { RINKEBY_NETWORK } from './PliantCryptoFrontend/PliantConfig';
import { ethers } from 'ethers';
import { Buffer } from "buffer/";
import { isPresaleEligible } from './contracts/Contract';
import Roadmap from './pages/Roadmap/Roadmap';

window.Buffer = window.Buffer || Buffer;

pliantConfig.API_URI = 'https://ssm7gk1my6.execute-api.us-east-1.amazonaws.com/main';
pliantConfig.INFURA_API_KEY = '53f5e30668b24e78bda2e6724f6c2034';
pliantConfig.NETWORK = RINKEBY_NETWORK;

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '60px',
  transition: transitions.SCALE
}

const config = {
  attemptImmediateConnection: false, // Calls connectWallet as soon as the component loads
  contractAddress: '0xe80F4bDFe92A848960689A6D4975a3644eeF74bF', // The address of the address contract
  abi: abi, // The abi of the contract
  getNftInfo: async (contract, wallet) => {
    if (wallet && await isPresaleEligible(contract, wallet)) {
      return new NFTInfo(
        ethers.utils.formatEther(await contract.whitelistPrice()),
        await contract.maxTokens(),
        await contract.totalSupply(),
        [1, await contract.maxWhitelistMint()]
      );
    }
    return new NFTInfo(
      ethers.utils.formatEther(await contract.salePrice()),
      await contract.maxTokens(),
      await contract.totalSupply(),
      [1, await contract.maxOrder()]
    );
  },
  defaultNftInfo: new NFTInfo(0.1, 10000, 0, [0, 0]),
  mobileRedirect: () => { window.location.href = 'https://metamask.app.link/dapp/app.tattedapesclub.com/mint' }
};

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Routes>
          <Route path='/' element={<Island />} includeAnimatedImage />
          <Route path='/mint' element={<Mint config={config} />} includeAnimatedImage />
          <Route path='/roadmap' element={<Roadmap />} includeAnimatedImage />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </Router>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
