import { Button, Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import NavBar from "../../components/NavBar/NavBar";

import "./styles.css";

export default function Roadmap() {
    return (
        <>
            <NavBar />
            <Container fluid>
                <Row>
                    <Col xs={{span: 8, offset: 2}} className="mt-5 pt-5">
                        <h1 className={`title text-center ${isMobile && "mobile-title"}`}>Roadmap</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 8, offset: 2}} className="">
                        <p className={`subtitle text-center ${isMobile && "mobile-subtitle"}`}>Coming soon!</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span: 4, offset: 4}} xs={{span: 6, offset: 3}}>
                        <div className="d-grid gap-2">
                            <Button variant="primary home-button" size="lg" href="/">
                                Home
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}