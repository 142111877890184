import { getMainContainerWidthInPX } from "./components/MainContainer";

export function generateShadow(x, y, blur, spread, opacity) {
    return {
        WebkitBoxShadow: `${x}px ${y}px ${blur}px ${spread}px rgba(0,0,0,${opacity})`,
        boxShadow: `${x}px ${y}px ${blur}px ${spread}px rgba(0,0,0,${opacity})`
    };
}

export function rgb(r, g, b) {
    if (g == undefined && b == undefined) {
        return `rgb(${r}, ${r}, ${r})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
}

export function generateBackgroundImage(src, backgroundSize='cover') {
    return {
        backgroundImage: `url(${src})`,
        backgroundSize: backgroundSize,
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat'
    };
}