import React from "react";
import { ErrorMessages } from "../Utils";
import { ethers } from 'ethers';
import { withAlert } from "react-alert";
import { isMobile } from "react-device-detect";
import { pliantConfig } from "../PliantConfig";

const { ethereum } = window;


export class NFTInfo {
    price = 0;
    maxSupply = 0;
    totalSupply = 0;
    mintRange = [1, 1];

    constructor(price, maxSupply, totalSupply, mintRange) {
        this.price = price;
        this.maxSupply = maxSupply;
        this.totalSupply = totalSupply;
        this.mintRange = mintRange
    }
}


/**
 * The wallet higher order component enables the wrapped component to be able to connect to a crypto wallet and interact with a smart contract.
 * 
 * Requires the following 'config' parameters:
 * 
 *  - contract: (string) The address of the address contract
 *  - abi: (object) The abi of the contract
 *  - mobileRedirectUrls: ([string: string]) A map of redirect options to the redirect urls for mobile users
 *  - getNftInfo: (function) async function that returns an NFTInfo object
 *  - defaultNftInfo: (NFTInfo) Default value for NFT info
 * 
 * It passes down the following props:
 * 
 *  - wallet: (string | null) Is null when no wallet is connected
 *  - connectWallet: (function return bool) Checks if the wallet is connected and requests connection if it is not, updates 'wallet'
 *  - contract: (object | null) The contract that can be interacted with
 *  - nft: (NFTInfo) The current NFT info object
 *  - openseaWalletLink: (string | null) A link to the users opensea wallet
 *  - updateNftInfo: (function) Updated the nft info
 */
export function withWallet(WrappedComponent) {
    return withAlert()(
        class extends React.Component {

            constructor() {
                super();
                this.state = {
                    wallet: null,
                    contract: null,
                    nft: new NFTInfo(0, 0, 0),
                    openseaWalletLink: null,
                    signer: null
                };
                this.isConnectionEnabled = this.isConnectionEnabled.bind(this);
                this.isWalletConnected = this.isWalletConnected.bind(this);
                this.requestWalletConnection = this.requestWalletConnection.bind(this);
                this.connectWallet = this.connectWallet.bind(this);
                this.setupContract = this.setupContract.bind(this);
                this.updateNftInfo = this.updateNftInfo.bind(this);
                this.signMessage = this.signMessage.bind(this);
            }

            /**
             * On component mount, update the nft info with the default and setup the contract.
             */
            componentDidMount() {
                if (this.props.config.defaultNftInfo) {
                    this.setState({ nft: this.props.config.defaultNftInfo });
                }
                this.setupContract();
            }

            /**
             * Calls getNFTInfo and updates the state.
             */
            async updateNftInfo(contractOverride=null) {
                if (this.props.config.getNftInfo === undefined) {
                    return;
                }
                try {
                    const nft = await this.props.config.getNftInfo(contractOverride !== null ? contractOverride : this.state.contract, this.state.wallet);
                    this.setState({ nft: nft });
                } catch (error) {
                    console.log(error);
                    this.props.alert.error(ErrorMessages.FAILED_TO_GET_NFT_INFO);
                }
            }

            /**
             * Signs a message using the wallet.
             */
            async signMessage(message) {
                if (this.state.signer === null) {
                    return null;
                }
                return await this.state.signer.signMessage(message);
            }

            /**
            * Checks if metamask or other supported crypto connection is enabled.
            * @returns Bool
            */
            isConnectionEnabled() {
                if (!ethereum) {
                    return false;
                }
                return true;
            }

            /**
             * Checks if the users wallet is connected.
             * @returns String or null
             */
            async isWalletConnected() {
                const accounts = await ethereum.request({ method: 'eth_accounts' });
                if (accounts.length !== 0) {
                    this.setState({ wallet: accounts[0] });
                    return true;
                }
                return false;
            }

            /**
             * Requests wallet connection.
             * @returns Bool
             */
            async requestWalletConnection() {
                try {
                    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                    if (accounts.length > 0) {
                        this.setState({ wallet: accounts[0] });
                        return true;
                    } else {
                        return false;
                    }
                } catch (err) {
                    return false;
                }
            }

            /**
             * Sets up the contract and then calls getNftInfo.
             */
            async setupContract() {
                if (this.state.wallet === null) {
                    const defaultProvider = new ethers.providers.InfuraProvider(pliantConfig.NETWORK, pliantConfig.INFURA_API_KEY);
                    const contract = new ethers.Contract(this.props.config.contractAddress, this.props.config.abi, defaultProvider);
                    this.setState({ contract: contract });
                    this.updateNftInfo(contract);
                } else {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner();
                    const contract = new ethers.Contract(this.props.config.contractAddress, this.props.config.abi, signer);
                    this.setState({ contract: contract, signer: signer });
                    this.updateNftInfo(contract);
                }
            }

            /***
             * Requests connection to the users wallet. Returns null if the wallet is connected and an error message otherwise.
             */
            async connectWallet(redirect = false) {
                if (!this.isConnectionEnabled()) {
                    // If crypto connection is not enabled, return error message
                    if (isMobile && redirect) {
                        this.props.config.mobileRedirect();
                    }
                    this.props.alert.error(ErrorMessages.CONNECTION_NOT_ENABLED);
                    return false;
                }
                const walletConnected = await this.isWalletConnected()
                if (!walletConnected) {
                    // If wallet is not connected, request connection
                    const wasRequestSuccessful = await this.requestWalletConnection();
                    if (!wasRequestSuccessful) {
                        // If connection request was not successful, return error message
                        this.props.alert.error(ErrorMessages.WALLET_COULD_NOT_BE_CONNECTED);
                        return false;
                    }
                }
                // Setup contract
                this.setupContract();
                // Set opensea link
                this.setState({ openseaWalletLink: `https://opensea.io/${this.state.wallet}?search[sortBy]=CREATED_DATE&search[sortAscending]=false` });
                // return true
                return true;
            }

            render() {
                return <WrappedComponent
                    wallet={this.state.wallet}
                    contract={this.state.contract}
                    connectWallet={this.connectWallet}
                    nft={this.state.nft}
                    openseaWalletLink={this.state.openseaWalletLink}
                    updateNftInfo={this.updateNftInfo}
                    signMessage={this.signMessage}
                    {...this.props}
                />;
            }
        }
    );
}