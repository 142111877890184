import React from "react";


export function BasicImage({ className, width, image, height }) {
    const h = height === undefined ? width : height;
    return (
        <div
            className={className}
            style={{
                width: width,
                height: h,
                backgroundPosition: '50% 50%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${image})`
            }}
        />
    );
}