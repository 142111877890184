import { getMainContainerWidthInPX } from "./MainContainer";
import SubContainer from "./SubContainer";

export default function AnimatedImage(props) {
    const mainContainerWidth = getMainContainerWidthInPX();
    const contentHeight = Math.round(mainContainerWidth * props.inputHeight / props.inputWidth);
    const height = contentHeight;
    const width = Math.round(height * props.inputWidth / props.inputHeight);
    return (
        <SubContainer
            backgroundColor={'black'}
            height={height}
            width={width}
        >
            <video style={{
                zIndex: 0
            }} height={height} autoPlay muted loop id="video" poster={props.inputImage}>
                <source src={props.inputSrc} type='video/mp4' />
            </video>
            {props.children}
        </SubContainer>
    );
}