import { useState } from 'react';
import Spinner from 'reactjs-simple-spinner';

export function Button({ action, loading, title, backgroundColor, fontSize, textOnly, textStyle, highlightStyle }) {
    const [isSelected, setIsSelected] = useState(false);
    var style = {
        fontSize: fontSize ?? '22px',
        width: 'fit-content',
        borderRadius: '8px',
        cursor: 'pointer',
        userSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        ...textStyle,
    };
    if (textOnly) {
        style = {
            padding: '5px 40px 5px 40px',
            textDecoration: 'underline',
            color: 'rgb(150,150,150)',
            ...style
        };
    } else {
        style = {
            padding: '20px 40px 20px 40px',
            WebkitBoxShadow: '0px 0px 24px -3px rgba(0,0,0,0.37)',
            boxShadow: '0px 0px 24px -3px rgba(0,0,0,0.37)',
            backgroundColor: backgroundColor,
            ...style
        };
    }
    if (isSelected) {
        style = {
            ...style,
            ...highlightStyle
        };
    }
    return (
        <div
            className={backgroundColor == null && !textOnly ? 'button-primary-color' : ''}
            onClick={(a) => {
                setIsSelected(true);
                setTimeout(() => {
                    setIsSelected(false);
                }, 250);
                setTimeout(() => {
                    action();
                }, 100)
            }}
            style={style}
        >
            {loading ? (<Spinner lineSize={5} speed={1.4} lineFgColor="rgb(80,80,80)" />) : title}
        </div >
    );
}