import plus from '../Images/plus.png';
import minus from '../Images/minus.png';


export function Quantity(props) {
    const button = {
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        backgroundColor: 'rgb(72, 78, 71)',
        backgroundPosition: '50% 50%',
        backgroundSize: '50%',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
    };
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center'
        }}>
            <div style={{...button, backgroundImage: `url(${minus})`}} onClick={props.minus} />
            <div style={{
                marginLeft: '22px',
                marginRight: '22px',
                fontSize: '25px',
                minWidth: '32px',
                textAlign: 'center',
                color: 'rgb(235, 235, 235)',
                ...props.textStyle
            }}>{props.quantity}</div>
            <div style={{...button, backgroundImage: `url(${plus})`}} onClick={props.plus} />
        </div>
    );
}